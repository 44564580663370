<template lang="html" src="./serialNumberPresentation.template.vue"></template>

<style lang="scss" src="./serialNumberPresentation.scss"></style>

<script>

import SupportEmail from '../../components/supportEmail/SupportEmail';

const i18nData = require('./serialNumberPresentation.i18n');

export default {
  name: 'SerialNumberPresentation',
  components: {
    SupportEmail
  },
  props: {
  },

  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {

    };
  },
  methods: {
    openSupportDialog() {
      this.$refs.supportEmailRef.dialogSupportEmail = true;
    }
  },
};
</script>
