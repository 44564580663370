var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "component-serialNumberPresentation mb-4 py-4"
  }, [_c('div', {
    staticClass: "background-img-1"
  }), _c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "d-flex align-center justify-center mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-custom px-2 py-1"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Trace')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('YouPartsThanksToThe').toLowerCase()) + " ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('SerialNumber').toLowerCase()) + " ")])])])], 1), false ? _c('v-img', {
    staticClass: "background-img",
    attrs: {
      "src": "https://www.beelse.com/wp-content/themes/theme-beelse/assets/img/map.svg",
      "contain": ""
    }
  }) : _vm._e(), _c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-img', {
    staticClass: "demo-img",
    attrs: {
      "src": require("../../assets/serialNumberExample2.png"),
      "max-height": "450px",
      "min-height": "300px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "5",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "text-center px-2 text-custom"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Label')) + " ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Automatically').toLowerCase()) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('YourPartsForEachOrder').toLowerCase()) + " ")])])])], 1), _c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('div', {
    staticClass: "text-center px-2 text-custom py-1"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Retrieve')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('AllYourPartsOn').toLowerCase()) + " CMT ")])])]), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-img', {
    staticClass: "demo-img",
    attrs: {
      "src": require("../../assets/serialNumberTraceability.png"),
      "max-height": "450px",
      "min-height": "300px",
      "contain": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', {
    staticClass: "d-flex align-end",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-img', {
    staticClass: "demo-img",
    attrs: {
      "src": require("../../assets/serialNumberTool4.png"),
      "max-height": "450px",
      "min-height": "300px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "5",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "text-center px-2 text-custom"
  }, [_c('span', {
    staticClass: "font-weight-bold pa-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('Chose')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('TheSettingsForEachOfYourParts').toLowerCase()) + " ")])])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "mx-auto"
  }, [_c('div', {
    staticClass: "text-center px-2 text-custom py-1"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Receive')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('YourPartsEmbeddedWithTheir').toLowerCase()) + " ")]), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('SerialNumber').toLowerCase()) + " ")])])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "demo-img",
    attrs: {
      "src": require("../../assets/sn_demo.png"),
      "max-height": "450px",
      "min-height": "300px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  })], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('v-col', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('SupportEmail', {
    ref: "supportEmailRef",
    attrs: {
      "hidden-pre-subject": `[Brand-${_vm.$route.params.brandUUID}]`,
      "default-subject": _vm.$t('SerialNumberInformationSubject'),
      "display-button": false,
      "default-text": _vm.$t('DefaultMailBodyContent', {
        firstname: _vm.$appConfig.user.firstname,
        lastname: _vm.$appConfig.user.lastname,
        brand: _vm.$appConfig.brand.name
      })
    }
  }), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "x-large": "",
      "color": "orange"
    },
    on: {
      "click": _vm.openSupportDialog
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-headset ")]), _c('span', {
    staticClass: "ml-4 white--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('IWantMoreInformation')) + " ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }