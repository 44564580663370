<template lang="html" src="./pageTraceability.template.vue"></template>

<style lang="scss" src="./pageTraceability.scss"></style>

<script>
import { BrandSerialNumbers } from '@cloudmanufacturingtechnologies/portal-components';
import SerialNumberPresentation from '../../components/serialNumberPresentation/SerialNumberPresentation';
import SupportEmail from '../../components/supportEmail/SupportEmail';

const i18nData = require('./pageTraceability.i18n');

export default {
  name: 'PageTraceability',
  components: {
    BrandSerialNumbers,
    SerialNumberPresentation,
    SupportEmail
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brandSerialNumbers: null
    };
  },
  created() {
    this.getBrandPartSerialNumbers();
  },
  mounted() {
  },
  methods: {
    getBrandPartSerialNumbers() {
      if(!this.$appConfig.brand.features.enableSerialNumber) {
        return;
      }
      this.$apiInstance.auditBrandPartsSerialNumbers(this.$route.params.brandUUID).then(data => {
        this.brandSerialNumbers = data;
      });
    }
  },
};
</script>
