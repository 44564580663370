var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-traceability"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('Traceability')) + " ")])]), _c('v-col', [!_vm.$appConfig.brand.features.enableSerialNumber ? _c('v-alert', {
    attrs: {
      "type": "info",
      "text": "",
      "dense": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('NoAccessToSerialNumberTitle')) + " ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [!_vm.$appConfig.brand.features.enableSerialNumber ? _c('SupportEmail', {
    ref: "support",
    attrs: {
      "hidden-pre-subject": `[Brand-${_vm.$route.params.brandUUID}]`,
      "default-subject": _vm.$t('SerialNumberInformationSubject'),
      "default-text": _vm.$t('DefaultMailBodyContent', {
        firstname: _vm.$appConfig.user.firstname,
        lastname: _vm.$appConfig.user.lastname,
        brand: _vm.$appConfig.brand.name
      }),
      "display-button": true,
      "btn-text": _vm.$t('IWantMoreInformation')
    }
  }) : _vm._e()], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-5"
  }), _vm.brandSerialNumbers ? _c('BrandSerialNumbers', {
    attrs: {
      "brand": _vm.$appConfig.brand,
      "brand-serial-numbers": _vm.brandSerialNumbers
    }
  }) : !_vm.$appConfig.brand.features.enableSerialNumber ? _c('div', [_c('SerialNumberPresentation')], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }